/* eslint-disable */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  DropDownButton,
  HorizontalFlexedWrapper,
  ImageWrapper,
  VerticalFlexedWrapper,
} from "../../Elements";
import { Text } from "../../Home/Blogs";

import Right from "../../../assets/Icons/svg/dropdown-right.svg";
import CartIcon from "../../../assets/Icons/svg/chart-black.svg";

import Prod1 from "../../../assets/Images/prod1.svg";
import Prod2 from "../../../assets/Images/prod2.svg";
import Prod3 from "../../../assets/Images/prod3.svg";
import Prod4 from "../../../assets/Images/prod4.svg";
import Prod5 from "../../../assets/Images/prod5.svg";
import Prod6 from "../../../assets/Images/prod6.svg";
import Prod7 from "../../../assets/Images/prod7.svg";
import Prod8 from "../../../assets/Images/prod8.svg";
import { useNavigate } from "react-router";
import ProductDataService from "../../../Services/ProductDataService";
import { Loader } from "semantic-ui-react";
import { TruncateText } from "../../../utils";

const Wrapper = styled.div`
  width: 80%;
  margin: 5% 10% 5% 10%;

  @media (max-width: 1400px) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  width: 80%;
  margin: 5% 10% 5% 10%;

  @media (min-width: 1400px) {
    display: none;
  }
`;

const ListingMenuWrapper = styled.div`
  width: 80%;
  // margin-left: 20%;
  // margin-top: 15vh;
  overflow-x: auto;
  white-space: no-wrap;

  @media (max-width: 1400px) {
    display: none;
  }
`;

const MenuList = [
  { name: "All Products", text: "All Products" },
  { name: "Best Sellers", text: "Best Sellers" },
  { name: "Kaftan", text: "Kaftan" },
  { name: "Athleisure", text: "Athleisure" },
  { name: "Agbada", text: "Agbada" },
  { name: "Suits", text: "Suits" },
  { name: "Art", text: "Art" },
];

const ProductListing = [
  {
    name: "Jacquard wine kaftan",
    details: "Kaftan | Wine",
    price: "$125.00",
    image: Prod1,
    category: "Kaftan",
  },
  {
    name: "Coastline Jacket + Fedora",
    details: "Jackets | Brown",
    price: "$200.00",
    image: Prod2,
    category: "Athleisure",
  },
  {
    name: "White Double Breasted Suit",
    details: "Suits | White",
    price: "$450.00",
    image: Prod3,
    category: "Kaftan",
  },
  {
    name: "Jacquard wine kaftan",
    details: "Kaftan | Wine",
    price: "$120.00",
    image: Prod4,
    category: "Kaftan",
  },
  {
    name: "Coastline Jacket + Fedora",
    details: "Jackets | Brown",
    price: "$99.99",
    image: Prod5,
    category: "Kaftan",
  },
  {
    name: "White Double Breasted Suit",
    details: "Suits | White",
    price: "$45.09",
    image: Prod6,
    category: "Suits",
  },
  {
    name: "Jacquard wine kaftan",
    details: "Kaftan | Wine",
    price: "$100.09",
    image: Prod7,
    category: "Kaftan",
  },
  {
    name: "Coastline Jacket + Fedora",
    details: "Jackets | Brown",
    price: "$560.99",
    image: Prod8,
    category: "Agbada",
  },
];

const Listings = ({ selectedProduct, setSelectedProduct }) => {
  console.log(selectedProduct);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(Boolean);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await ProductDataService.getCategory();
      if (response) {
        console.log(response.data.data);
        setCategories([{ name: "All Products" }, ...response.data.data]);
      } else {
        console.log("Could not get categories");
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // const navigate = useNavigate();

  return (
    <>
      <Wrapper>
        <HorizontalFlexedWrapper
          width={"100%"}
          height={"fit-content"}
          elements={
            <>
              <Text width={"20%"}>
                {selectedProduct === null ? "My Cart (3)" : ""}
              </Text>
              <ListingMenuWrapper>
                <HorizontalFlexedWrapper
                  width={"100%"}
                  height={"fit-content"}
                  elements={
                    <>
                      <Menu
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                      />
                    </>
                  }
                />
              </ListingMenuWrapper>
            </>
          }
        />

        <HorizontalFlexedWrapper
          width={"100%"}
          height={"fit-content"}
          align={"flex-start"}
          justify={"left"}
          elements={
            <>
              <VerticalFlexedWrapper
                width={"20%"}
                height={"fit-content"}
                elements={
                  <>
                    <DropDownButton
                      text={"LATEST PIECES"}
                      width={"70%"}
                      bgColor={"#D9D9D9"}
                      Icon={
                        <>
                          <ImageWrapper
                            width={"15%"}
                            height={"25px"}
                            image={Right}
                          />
                        </>
                      }
                    />
                    <DropDownButton
                      text={"CATEGORY 1"}
                      width={"70%"}
                      bgColor={"#D9D9D9"}
                      Icon={
                        <>
                          <ImageWrapper
                            width={"15%"}
                            height={"25px"}
                            image={Right}
                          />
                        </>
                      }
                    />
                    <DropDownButton
                      text={"CATEGORY 2"}
                      width={"70%"}
                      bgColor={"#D9D9D9"}
                      Icon={
                        <>
                          <ImageWrapper
                            width={"15%"}
                            height={"25px"}
                            image={Right}
                          />
                        </>
                      }
                    />
                  </>
                }
              />

              <ProductWrapper>
                <ProductCard
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                />
              </ProductWrapper>
            </>
          }
        />
      </Wrapper>

      <MobileWrapper>
        <DropDownButton
          bgColor={"#D9D9D9"}
          list={categories}
          change={selectedProduct?.name}
          text={"All Products"}
          onSelect={(option, index) => {
            setSelectedProduct(option);
          }}
        />
        <ProductWrapper>
          <ProductCard
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        </ProductWrapper>
      </MobileWrapper>
    </>
  );
};

const Menu = ({ selectedProduct, setSelectedProduct }) => {
  const [clicked, setClicked] = useState("all");

  const [loading, setLoading] = useState(Boolean);
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await ProductDataService.getCategory();
      if (response) {
        console.log(response.data.data);
        setCategories(response.data.data);
      } else {
        console.log("Could not get categories");
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <Text
        width={"15%"}
        size={"15px"}
        onClick={() => {
          setClicked("all");
          setSelectedProduct({ name: "All Products" });
        }}
        style={
          clicked === "all"
            ? {
                fontWeight: "bolder",
                cursor: "pointer",
                textTransform: "capitalize",
              }
            : { cursor: "pointer", textTransform: "capitalize" }
        }
      >
        {"All Products"}
      </Text>
      {categories &&
        categories.map((item, index) => (
          <Text
            key={index}
            width={"20%"}
            size={"15px"}
            onClick={() => {
              setClicked(index);
              setSelectedProduct(item);
            }}
            style={
              index === clicked
                ? {
                    fontWeight: "bolder",
                    cursor: "pointer",
                    textTransform: "capitalize",
                  }
                : { cursor: "pointer", textTransform: "capitalize" }
            }
          >
            {item.name}
          </Text>
        ))}
    </>
  );
};

const ProductCard = ({ selectedProduct, setSelectedProduct }) => {
  const navigate = useNavigate();
  const [ProductList, setProductList] = useState([]);
  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(Boolean);
  // console.log(selectedProduct?.name);

  const ProductFilteredByCategory =
    selectedProduct?.name === "All Products" ||
    selectedProduct?.name === "Best Sellers"
      ? ProductList
      : ProductList.filter((prod) => prod.category === selectedProduct?.name);

  const fetchAllProducts = async () => {
    try {
      setLoading(true);
      const response = await ProductDataService.getAllProduct();
      if (response) {
        // console.log(response.data.data);
        setProductList(response.data.data);
        setLoading(false);
      } else {
        console.log("error has occured");
        setLoading(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await ProductDataService.getCategory();
      if (response) {
        console.log(response.data.data);
        setCategories(response.data.data);
      } else {
        console.log("Could not get categories");
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchAllProducts();
    fetchCategories();
    // setSelectedProduct({ name: "All Products" });
  }, []);

  return (
    <>
      {selectedProduct === null && ProductList && loading ? (
        <Loader active={loading} inline="centered" />
      ) : selectedProduct?.name === undefined ||
        selectedProduct?.name === "All Products" ? (
        ProductList.map((product, index) => (
          <VerticalFlexedWrapper
            onClick={() => setSelectedProduct(product)}
            width={"28%"}
            height={"45vh"}
            margin={"1%"}
            mobileWidth={"48%"}
            elements={
              <>
                <ProductImage
                  background={`'${product.images[0]}'`}
                  key={index}
                  onClick={() =>
                    navigate(
                      `/product/${product.name}?category=${product.category}&name=${product.name}&price=${product.price}&details=${product.description}&id=${product.id}`
                    )
                  }
                >
                  <ProductBadge>New</ProductBadge>
                </ProductImage>
                <VerticalFlexedWrapper
                  height={"30%"}
                  width={"100%"}
                  justify={"center"}
                  elements={
                    <>
                      <Text
                        width={"100%"}
                        line={"0px"}
                        align={"left"}
                        fontSmall={"16px"}
                        smallLine={"20px"}
                        style={{ fontWeight: "bold" }}
                      >
                        {product.name}
                      </Text>
                      <Text
                        line={"18px"}
                        width={"100%"}
                        align={"left"}
                        fontSmall={"14px"}
                        smallLine={"14px"}
                      >
                        {product.category} | {TruncateText(product.description)}
                      </Text>

                      <HorizontalFlexedWrapper
                        width={"100%"}
                        height={"10%"}
                        justify={"flex-start"}
                        elements={
                          <>
                            <ImageWrapper
                              image={CartIcon}
                              width={"30px"}
                              height={"30px"}
                            />
                            <Text
                              width={"50%"}
                              align={"left"}
                              style={{ marginLeft: "10px" }}
                            >
                              ${product.price}
                            </Text>
                          </>
                        }
                      />
                    </>
                  }
                />
              </>
            }
          />
        ))
      ) : (
        ProductFilteredByCategory.map((product, index) => (
          <VerticalFlexedWrapper
            width={"28%"}
            height={"45vh"}
            margin={"1%"}
            mobileWidth={"48%"}
            elements={
              <>
                <ProductImage
                  background={`'${product?.images[0]}'`}
                  key={index}
                  onClick={() =>
                    navigate(
                      `/product/${product.name}?category=${product.category}&name=${product.name}&price=${product.price}&details=${product.description}&id=${product.id}`
                    )
                  }
                >
                  <ProductBadge>New</ProductBadge>
                </ProductImage>
                <VerticalFlexedWrapper
                  height={"30%"}
                  width={"100%"}
                  justify={"center"}
                  elements={
                    <>
                      <Text
                        width={"100%"}
                        line={"0px"}
                        align={"left"}
                        fontSmall={"16px"}
                        smallLine={"20px"}
                        style={{ fontWeight: "bold" }}
                      >
                        {product?.name}
                      </Text>
                      <Text
                        line={"18px"}
                        width={"100%"}
                        align={"left"}
                        fontSmall={"14px"}
                        smallLine={"14px"}
                      >
                         {product.category} | {TruncateText(product.description)}
                      </Text>

                      <HorizontalFlexedWrapper
                        width={"100%"}
                        height={"10%"}
                        justify={"flex-start"}
                        elements={
                          <>
                            <ImageWrapper
                              image={CartIcon}
                              width={"30px"}
                              height={"30px"}
                            />
                            <Text
                              width={"50%"}
                              align={"left"}
                              style={{ marginLeft: "10px" }}
                            >
                              {product?.price}
                            </Text>
                          </>
                        }
                      />
                    </>
                  }
                />
              </>
            }
          />
        ))
      )}
      {/* {selectedProduct !== null &&
} */}
    </>
  );
};

const ProductImage = styled.div`
  background: url(${(props) => (props.background ? props.background : "")});
  background-position: contain;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 70%;
  width: 100%;
  position: relative;

  @media (max-width: 1400px) {
    background-size: 150%;
    background-position: center;
  }
`;

const ProductBadge = styled.div`
  position: absolute;
  background: red;
  color: #ffffff;
  font-family: Josefin Sans;
  text-transform: capitalize;
  top: 10px;
  right: 10px;
  padding: 8px;
`;

const ProductWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: left;
  height: fit-content;

  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export default Listings;
